* {
  margin: 0;
  border: 0;
  box-sizing: border-box;
}

:root {
  text-align: center;
  --dark-blue: #23232e;
  --nav-bg-secondary: #141418;
  --sky-blue: #31d2f2;
}

body::-webkit-scrollbar {
  width: 1rem;
}

body::-webkit-scrollbar-track {
  background: #1e1e24;
}

body::-webkit-scrollbar-thumb {
  background: var(--sky-blue);
}

.fullApp {
  font-family: "Quicksand", sans-serif;
  margin-bottom: 75px;
  width: calc(100vw - 1rem);
}

a {
  font-weight: bold;
  font-size: 25px;
  color: var(--sky-blue);
  text-decoration: none;
  font-weight: 700 !important;
}

.navbar {
  background: var(--sky-blue);
  height: 5rem;
  width: 100vw;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.519);
  position: fixed;
  transition: 200ms ease;
}

.navbar:hover {
  height: 10rem;
}

.nav-links {
  display: flex;
  margin: 0 auto;
  width: 50%;
  justify-content: space-around;
}

.nav-links a {
  color: var(--dark-blue);
}

.link-group {
  padding: 10px;
  width: 150px;
}

.link-group:hover {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.nav-pic {
  width: 50px;
}

.nav-text {
  display: none;
}

.navbar:hover .nav-text {
  display: block;
}

.main {
  margin-top: 5rem;
}

.header {
  font-family: "Sacramento", cursive;
  font-size: 90px;
  text-align: center;
  background: var(--sky-blue);
  width: fit-content;
  margin: 20px auto 50px auto;
  border-radius: 50%;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.519);
}

.container {
  width: 100%;
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.filer-container {
  padding: 10px;
  border: 2px solid black;
  border-radius: 10px;
  width: 70%;
  margin: 50px auto 0 auto;
}

.tech-checkboxes {
  display: flex;
  justify-content: space-around;
  text-align: left;
}

.tags-list {
  list-style: none;
}

.main-select,
.sub-select {
  accent-color: var(--sky-blue);
}

.main-tech-label {
  font-size: 1.25em;
}

.main-tech-label,
.sub-tech-label {
  margin-left: 5px;
}

#year-text,
#db-text {
  margin-bottom: 0;
}

.project-accordion {
  width: 100%;
  text-align: center;
}

.accordion-button {
  background-color: #31d2f2 !important;
  font-weight: bold;
}

.accordion-button:focus {
  border-color: white !important;
  box-shadow: none !important;
}

.project-header {
  margin-top: 50px;
  font-weight: bold;
}

.project-image {
  width: 450px;
  height: 300px;
  margin: 1.5rem;
  object-fit: cover;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  border: none;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0, 1);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.519);
}

.project:hover {
  box-shadow: 0px 30px 18px -8px rgba(0, 0, 0, 0.1);
  transform: scale(1.05, 1.05);
}

.project-container {
  margin-bottom: 20px;
}

.spacer {
  height: 90px;
}

.about-summary-container {
  width: 70%;
  margin: auto;
}

.about-summary {
  font-size: 1.4rem;
  margin-bottom: 5px;
}

.contactCard {
  margin-top: 3rem;
  width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.519);
  border-radius: 7px;
  margin-bottom: 50px;
}

.modal-buttons {
  margin: 10px auto;
}

.modal-button {
  margin: 0px 10px;
  width: 175px;
  height: 40px;
}

#drop-down-nav {
  font-size: 30px;
  padding: 10px 29px;
  margin-top: 2vw;
  text-align: left;
}

#drop-down-nav:hover {
  box-shadow:
    0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.title-container {
  background: radial-gradient(
    circle,
    var(--sky-blue) 65%,
    rgba(84, 84, 110, 1) 100%
  );
  width: fit-content;
  margin: 0 auto;
  border-radius: 50%;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.519);
}

#alycia {
  font-family: "Sacramento", cursive;
  font-size: 15vw;
  margin-top: 10vw;
}

#title {
  font-size: 4vw;
}

.link-row {
  display: flex;
  justify-content: space-around;
  margin-top: 50px;
  flex-wrap: wrap;
}

.link-row a {
  color: var(--sky-blue);
  display: flex;

  align-items: center;
}

.home-link {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.519);
  width: 400px;
  height: 280px;
  border-radius: 5px;
  margin: 10px;
}

.tech-pic {
  width: 200px;
  margin: 18px;
}

.carousel {
  width: 300px;
  margin: 5px auto 0 auto;
}

.icon-carousel-container {
  margin-top: 20px;
  margin-bottom: 10px;
  border-radius: 5px;

  background-color: var(--dark-blue);
}

.carousel-icon {
  margin-top: 10px;
  font-size: 9rem;
  color: var(--sky-blue);
}

.carousel-text {
  color: white;
}

.carousel-pic {
  margin-top: 20px;
  margin-bottom: 10px;
  width: 300px;
  border-radius: 5px;
  height: 200px;
}

.subheader {
  margin: 20px;
  font-weight: 700;
}

.tech-header {
  margin-top: 50px;
}

.technologies {
  width: 75%;
  margin: 20px auto;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.tech-item {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.519);
  border-radius: 5px;
  padding: 10px;
  margin: 10px 5px;
  width: 200px;
}

.tech-icon {
  font-size: 7rem;
  color: var(--sky-blue);
}

#projectButton {
  margin-bottom: 10px;
}

#email-submit {
  margin-top: 10px;
}

.outside-link,
.resume-download {
  color: var(--sky-blue);
}

.link-home {
  color: var(--sky-blue);
  margin-bottom: 50px;
}

.resume-subheader {
  font-family: "Sacramento", cursive;
  font-size: 50px;
  margin: 20px;
}

.experience-card {
  width: 75%;
  margin: 20px auto;
}

.experience-card-header,
.education-card-header,
.skills-card-header {
  background-color: var(--sky-blue) !important;
  font-size: 1.25rem;
  text-align: center;
}

.education-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.education-card {
  width: 275px;
  margin: 0 20px 20px 20px;
}

.skills-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.skills-card {
  text-align: left;
  width: 325px;
  margin: 0 20px 20px 20px;
}

.skills-card ul {
  margin-bottom: 1px;
}

.minor-skills {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 600px) {
  .fullApp {
    font-family: "Quicksand", sans-serif;
    margin-bottom: 75px;
    width: 100vw;
  }

  .navbar:hover {
    height: 5rem;
  }

  .navbar:hover .nav-text {
    display: none;
  }

  #alycia {
    font-size: 18vw;
  }

  #title {
    font-size: 6vw;
  }

  .nav-links {
    width: 100%;
  }

  .nav-pic {
    width: 35px;
  }

  .header {
    font-size: 70px;
  }

  .home-link h3 {
    font-weight: bold;
  }

  .contactCard {
    width: 75%;
  }

  .link-row {
    width: 75%;
    margin: 2rem auto;
    flex-direction: column;
    justify-content: center;
  }

  .home-link {
    width: 100%;
    margin: 5px;
    height: 245px;
  }

  .tech-pic {
    width: 55%;
  }

  .carousel {
    width: 75%;
    height: 10rem;
    margin: 20px auto 10px auto;
  }

  .icon-carousel-container {
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
    background-color: var(--dark-blue);
  }

  .carousel-icon {
    margin-top: 10px;
    font-size: 5rem;
    color: var(--sky-blue);
  }

  .carousel-text {
    font-size: 1rem;
  }

  .carousel-pic {
    margin-top: 10px;
    margin-bottom: 5px;
    width: 100%;
    border-radius: 5px;
    height: 150px;
  }

  .tech-checkboxes {
    flex-direction: column;
  }

  .project {
    border: 1px solid lightgray;
    border-radius: 10px;
    margin: 10px 0;
    padding: 15px;
  }

  .project-image {
    width: 300px;
    height: 200px;
    margin: 15px 0;
  }

  .experience-card, .education-card {
    width: 90%;
  }
}
